@import url("https://fonts.cdnfonts.com/css/vacation");


html, body {
  height: 100vh;
  margin: 0;;
}

.vacationGroupMain {
  color: #292128 !important;
  background-color: #00eef7;
  background-position: 100% 100%; /* Corrected the background-position */
}

body {
  display: flex;
  flex-direction: column;
}

.buttonLoadOrCreate {
  width: auto;
  padding: 12px 24px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff00ea, #00eef7);
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.vacationGroupMain {
  background-color: #fff;
}

.buttonLoadOrCreate:hover {
  border-color: #fff;
  transform: scale(1.05);
}

.primaryButton {
  width: auto;
  padding: 12px 24px;
  font-size: 1.5rem;
  margin-top: 3rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff00ea, #00eef7);
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.primaryButton:hover {
  border-color: #fff;
  transform: scale(1.05);
}

.vacationDate {
  margin-top: 1rem;
}

.centeredContainer {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;
}

.previousVacations {
  font-size: 1.4rem;
  margin-top: 2rem;
}

.previousVacations:before {
  content: "⤹";
  position: fixed;
  margin-top: 1.1rem;
  margin-left: 3.5rem;
  font-size: 1.75rem;
}

.prevVacationsList {
  list-style-type: disc;
  list-style-position: inside;
  display: inline-block;
  margin: 0 auto;
  font-size: 1.35rem;
  text-align: left;
}

.prevVacationsListParent {
  text-align: center;
}

.flexGroup {
  display: flex;
  gap: 15px;
  text-align: center;
}

.loadedVacationsText {
  margin-top: 1.3rem;
  cursor: pointer;
}

.mainInfoText {
  font-size: 1.8rem;
  padding: 15px;
  color: #000;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.space {
  margin: 20px 0;
  padding: 10px;
}

h1.vacation-planner {
  font-family: "Vacation", sans-serif;
  font-size: 4rem;
}

.flyingFrom {
  margin: 0px 1rem 0px 1rem;
}

.flyingTo {
  margin: 0px 1rem 0px 1rem;
}

.inputVacation1 {
  position: relative;
  padding: 8px 14px;
  font-size: 2.1rem;
  border: 2px solid #ccc;
  border-radius: 20px;
  outline: none;
  color: #212529;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.inputVacation1Date {
  position: relative;
  padding: 3px 12px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 20px;
  outline: none;
  color: #212529;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.vacationNameLabel {
  font-size: 2rem;
  margin: 2rem;
  display: block;
}

input {
  color: #333;
}

.label1 {
  font-size: 2rem;
  margin: 2rem;
  display: block;
}

.vacationNameMain {
  text-align: center;
  font-size: 4.25rem;
  margin: 2rem auto;
  box-shadow: #1aacd8 0px 0px 0px 3px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgba(244, 123, 255, 0.89) 10px -10px;
  display: inline-block;
  border-radius: 9px;
  padding: 0 1.85rem 0.1rem 1.85rem;
  word-wrap: break-word;
  max-width: 100%
}

.vacationDatesAndLocation {
  font-size: 2.75rem;
}

.vacationDateGroupMain {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.vacationDatesMain {
  background: rgba(233, 233, 233, 0.356);
  padding: 0.5rem;
  font-size: larger;
  border-radius: 12px;
  box-shadow: 1px 1px 1px rgba(17,17,26,0.2);
}

.planeIconFromTo {
  margin-top: 0.35rem;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.travelersButton {
  width: auto;
  padding: 0.38rem 0.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff00ea, #00eef7);
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.travelersButton:hover {
  border-color: #fff;
  transform: scale(1.03);
}

.gradient {
  background: linear-gradient(45deg, #ff00ea, #00eef7);
}

.travelers {
  overflow: hidden;
  margin: 2rem;
  text-align: center;
}

hr.travelersBreak {
  max-width: 60%;
  height: 0.35rem;
  background-color: #1aacd8;
  border-radius: 12px;
  border: none;
  margin: 0.65rem auto;
  opacity: 65%;
}

.travelersNames {
  justify-content: center;
  display: flex;
  gap: 3%;
  margin-top: 0.65rem;
}

.lodging {
  margin: 2rem;
}

.travelersContent {
  font-size: 1.5rem;
}

.mainVacationData {
  display: flex;
  gap: 2%;
}

.vacationInput1 {
  position: relative;
  font-size: 1.8rem;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  display: inline-block;
  border: 1px solid #333;
  padding: 0.25rem;
  border-radius: 7px;
}

.saveButton {
  border-radius: 6px;
  padding: 0.4rem 0.85rem;
  color: #fff;
  font-size: 1.35rem;
  background: rgb(20, 206, 45);
  border: none;
}


.saveButtonWider {
  border-radius: 6px;
  padding: 0.4rem 1.25rem;
  color: #fff;
  font-size: 1.75rem;
  background: rgb(20, 206, 45);
  border: none;
}


.addNewButton {
  border-radius: 6px;
  padding: 0.4rem 0.85rem;
  color: #fff;
  font-size: 1.75rem;
  border: none;
  background: #1aacd8;;
}

.saveBorder {
  border: 1px solid;
  background: rgb(20, 206, 45);
}

.spanForForm {
  margin: 1rem 0;
  font-size: 1.25rem;
  display: block;
  border-radius: 7px;
}

.editSpan {
  font-size: 1.5rem;
  display: block;
  border-radius: 7px;
}

.flexButtons {
  display: flex;
  margin: 1.5rem 0 0 0;
  justify-content: center;
  gap: 2rem;
}

.individualTravelerNames {
  font-size: 1.5rem;
}

.vacationDatesInOrder {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  max-width: 75%;
  margin: 0 auto 2rem auto;
}

.vacationDatesInOrder > div {
  flex: 1 1 calc(30% - 10px);
  box-sizing: border-box;
}

.vacationDayGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}


.icon-container {
  position: relative;
  display: inline-block;
}

.toAndFromPModal {
  font-size:2.25rem;
  color:#1aacd8;
  padding: 0.5rem 0 0.1rem 0;
}

.inputVacation2 {
  position: relative;
  padding: 8px 14px;
  font-size: 1.3rem;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 20px;
  outline: none;
  color: #000;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.inputVacation3 {
  position: relative;
  padding: 4px 12px;
  font-size: 3rem;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d1d1d1;
  text-align: center;
  outline: none;
  color: #000;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.daySummary {
  font-size: 3.5rem;
  margin-top: -0.15rem;
  text-align: center;
}

.centeredSaveButtonContainer {
  text-align: center;
  margin-top: 1.25rem;
}

.trashIconMobile {
  color: #e21212;
  margin-top: 0.5rem;
}

.trashIconTravelers {
  margin-left: .5rem;
  color: #e21212;
}

.infoTextBelowTitle {
  max-width: 900px;
  font-size: 1.5rem;
  margin: 2rem auto;
}

.iconVP {
  padding: 1.15rem;
  background-color: #1f1f1f;
  border-radius: 50%;
  color: white;
  transition: transform 0.3s ease, background-color 0.3s ease,
  box-shadow 0.3s ease;
  display: inline-block;
  animation: glow 3s infinite;
}

.iconGroupVP:hover .iconVP {
  background: linear-gradient(45deg, #ff00ea, #00eef7);
  box-shadow: 0 6px 15px rgba(172, 172, 172, 0.52);
  transform: scale(1.1);
}

.iconGroupVP:hover {
  transform: translateY(-5px);
}

.iconSubTextVP {
  color: #000;
  max-width: 85%;
  display: inline-block;
}

.iconGroupVP:hover .icon {
  animation: pulse 1s infinite;
}

.iconDivVP {
  margin: 0.5rem auto;
  font-size: 1.5rem;
  display: block;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 3px #ff00ea, 0 0 3px #00eef7,
      0 0 3px #ff00ea, 0 0 3px #00eef7;
  }
  50% {
    box-shadow: 0 0 7px #00eef7, 0 0 7px #ff00ea,
      0 0 7px #00eef7, 0 0 7px #ff00ea;
  }
  100% {
    box-shadow: 0 0 3px #ff00ea, 0 0 3px #00eef7,
      0 0 3px #ff00ea, 0 0 3px #00eef7;
  }
}

.vacationDayText {
  font-size: 2.2rem;
  box-shadow: inset 0 -0.1em 0 #ff00ea;
  display: block;
  width: fit-content;
  margin-bottom: 0.5rem;
  text-align: center;
}

.individualVacationDates {
  font-size: 1.15rem;
}

.whyYouNeedAccount {
  font-size: 1.05rem;
  margin: 1rem;
  transition: 0.2s ease;
}

.questionCircleNeedAccount {
  padding-right: 3px;
}

.whyYouNeedAccount:hover {
  transform: scale(1.035);
  animation: pulse 1s infinite;
}













@media screen and (max-width: 768px) {

  .infoTextBelowTitle {
    max-width: 90%;
    margin: 1rem auto;
  }

  .travelersNames {
    display: block;
  }

  .previousVacations:before {
    display: none;
  }
  
  .planeIconFromTo {
    margin-top:2.75rem;
  }
  
  .vacationDatesMain {
    margin: 1rem;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  
  hr.travelersBreak {
    max-width: 100%;
  }

  .vacationNameMain {
    margin: 2.2rem auto;
    padding: 0.5rem;
    font-size: calc(3.25rem + 1vw);
  }

  .vacationDatesAndLocation {
    text-align: center;
  }
  
  .flexGroup {
    padding: 1rem auto;
    display: block;
    text-align: center;
  }

  .vacationInput1 {
    position: relative;
    font-size: 120%;
    display: inline-block;
    border: 1px solid #333;
    padding: 0.25rem;
    border-radius: 7px;
  }

  .trashIconTravelers {
    margin-top: 1rem;
  }

  .daySummary {
    font-size: 2.785rem;
  }

  .gridMobile {
    display: grid;
  }

  .trashIconMobile {
    margin: 0 auto;
  }
  
}