.generateButton {
  width: auto;
  padding: 12px 20px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(40deg, #444444, #c8425b);
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #212529;
  }
  
  
  .generateButton:hover {
    border-color: #fff;
    transform: scale(1.05);
  }
  
  .generatedHeroP {
    font-weight: bold;
    font-size: 1px; 
    transition: font-size 0.25s ease-in-out;
  }
  
  .generatedHero.show .generatedHeroP {
    font-size: 24px; 
  }
  
  /* Particle Effect */
  .particlesContainer {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
  }
  
  .particle {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: particleExplosion 1.5s forwards;
  }
  
  @keyframes particleExplosion {
    0% {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(var(--x), var(--y)) scale(0.8);
    }
  }
  
  .backgroundContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  
  @media screen and (max-width: 768px) {
    .backgroundContainer {
      position: relative;
      width: 100%;
      height: 120vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  
  .fullScreenContainer {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .opacityScreenEffect {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  
  .contentWrapper {
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    overflow: hidden;
    width: 90%;
    box-sizing: border-box;
  }
  
  .historyContainer h3 {
    font-size: 20px;
    color: #E0E0E0;
    margin-bottom: 1rem;
  }
  
  .historyList {
    list-style-type: none;
    padding: 0;
  }
  
  .historyItem {
    font-size: 16px;
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #555555;
    color: #B0B0B0;
  }
  
  .historyItem .role {
    font-weight: bold;
    color: #c8425b;
  }
  
  
.generatedHero {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 1rem;
}

.historyContainer {
  text-align: left;
  margin-top: 2rem;
  padding: 10px;
  background-color: #2E2E2E;
  border-radius: 8px;
  max-height: 300px; 
  overflow-y: auto;
  box-sizing: border-box;
}

.historyContainer h3 {
  font-size: 20px;
  color: #E0E0E0;
  margin-bottom: 1rem;
}

.historyList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}