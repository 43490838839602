.portfolio-container {
    display: flex;
    justify-content: center;
    padding: 3rem;
    position: relative;
    z-index: 0;
    background-size: 5.2rem 5.2rem;
    background-color: rgb(20, 20, 20);
    background-repeat: repeat;
    background-image: linear-gradient(
      to right,
      rgb(230, 230, 230) 1px,
      transparent 0
    ),
    linear-gradient(to bottom, rgb(230, 230, 230) 1px, transparent 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.portfolio-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.8) 1%,
      rgba(20, 20, 20, 0.96) 40%,
      rgba(23, 23, 23, 1) 100%
    );
    z-index: -1;

}

.homeIconTopLeft {
    position: absolute;
    top: 20;
    color: rgb(219, 219, 219);
    z-index: 3;
    left: 20;
}

.vertical-timeline-element-content {
    background: rgba( 13, 13, 13, 0.7 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: rgb(219, 219, 219);
    border-top: 3px solid rgba(245, 8, 8, 0.664);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vertical-timeline-element-content:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 6px, rgba(0, 0, 0, 0.15) 0px 3px 6px;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid rgb(189, 17, 17);
}


.timeline-wrapper {
    width: 80%;
    z-index: 5;
    max-width: 1500px;
    padding: 30px;
    background: rgba(83, 83, 83, 0.425);
    box-shadow: 0 3px 12px 0 rgba(201, 48, 48, 0.37);
    backdrop-filter: blur(15.5px);
    border-radius: 12px;
    position: relative; /* Ensure it stacks correctly */
}

h2.vertical-timeline-element-title {
    font-size: 2.25rem;
    width: 100%;
    color: rgb(219, 219, 219);
}

.portfolipPBody {
    color: rgb(219, 219, 219);
}

.languagesUsed {
    padding-top: 0.4rem;
    color: rgb(245, 5, 5);
    font-size: 1.25rem;
    -webkit-text-stroke: 0.15px rgb(0, 0, 0);
}

a {
    color: inherit;

}

.vertical-timeline-element-icon {
    box-shadow: none !important;
}

.portfolioIconImg {
    width: 60px;
    transform: scale(1.215);
    height: 60px;
    border-radius: 48%;
    padding: 0.25rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 2px 0px, rgba(17, 17, 26, 0.18) 0px 0px 12px;
    background-color: rgb(219, 219, 219);
    margin-bottom: 15px;
    z-index: -55555;
}

.portfolioH1Wrapper {
    text-align: center;
    color: rgb(219, 219, 219);
    letter-spacing: 0.3rem;
    padding: 0.2rem 0 1.3rem 0;
    font-variant: small-caps;
}

h1.portfolioH1 {
    font-size: 4rem;
}

.linkAndCode {
    padding: 0.10rem 0.35rem 0.21rem 0.35rem;
    margin-top: 0.25rem;
    border-radius: 20px;
    background-color: rgb(189, 17, 17);
    color: white;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    transition: 0.2s ease;
  }

  .linkAndCode:hover > div {
    transform: translateY(-1.75px) scale(1.01);
  }



.linkAndCodeContainer {
    display: flex;
    gap: 10%;
    justify-content: start;
    padding: 0.15rem 0;
}

@media (max-width: 768px) {
    .timeline-wrapper {
        width: 97%;
        height: auto;
    }

    .portfolio-container { 
        padding: 3.5rem 0.5rem;
    }

    h2.vertical-timeline-element-title {
        font-size: 1.85rem;
        width: 100%;
        color: rgb(219, 219, 219);
    }

    .portfolioIconImg {
        width: 50px;
        height: 50px;
        transform: scale(1.0);
    }
  
    .portfolipPBody {
        font-size: 1rem !important;
    }

    .linkAndCode {
        padding: 0.08rem 0.25rem 0.19rem 0.25rem;
    }
}

