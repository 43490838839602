.hamburgerMenu {
  position: fixed;
  color: #000;
  overflow: hidden;
  z-index: 1000;

  display:none;
}

.menu-items {
  position: relative;
  padding: 15px;
  overflow: hidden;
  color: #000;
}

.menu-item {
  margin: 1.35rem 0;
  overflow: hidden;
}

.menu-item a span {
  display: inline-block;
  text-indent: 0;
}

.menu-item a span::before {
  content: "";
  display: block;
  height: 0;
  width: 100%;
}

.menu-item a span {
  text-indent: 15px;
}

.custom-burger-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
}

.bm-burger-button {
  width: 36px;
  height: 30px;
}

.bm-menu {
  background: #f9f9f9;
  font-size: 1.15rem;
}

.bm-item-list a {
  overflow: hidden;
  padding: 0.5rem;
  color: #000;
  text-decoration: none;
}

.loginGroup h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.inputGroup {
  margin-bottom: 15px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
}

.inputGroup input {
  width: 98%;
  padding: 8px 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.inputText {
  color: #000;
  border-radius:7px;
}

.inputGroup input:focus {
  border-color: #000;
  outline: none;
}

.loginButton {
  width: 100%;
  padding: 8px 24px;
  font-size: 1.35rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff00ea, #00eef7);
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.loginButton:hover {
  border-color: #fff;
  transform: scale(1.03);
}

.registerText {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.registerText:hover {
  cursor: pointer;
}

.registerGroup h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.menu-item.coffee-button {
  margin-top: 2rem;
}

.menu-item.coffee-button a {
  display: inline-block;
  background-image: linear-gradient(135deg, #ff5f6d, #e98f29, #5c2bb8, #21238f);
  background-size: 300% 300%;
  color: #fff;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  border: 2px solid transparent;
  background-clip: padding-box;
  -webkit-background-clip: text;
  background-clip: border-box;
  animation: vaporwaveButtonGradient 4s ease infinite;
}

@keyframes vaporwaveButtonGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.menu-item.coffee-button a:hover {
  cursor: pointer;
}

span {
  color: #000;
}