@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* this css file is for styles that may be used across multiple pages */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff !important;
}

body {
  font-family: 'Poppins';
  height: 100%;
  font-weight: 'Normal'
}

* {
  font-family: 'Poppins';
}

.burgerButton {
  animation: colorChange 2.5s infinite alternate;
}

@keyframes colorChange {
  /*0% {
      color: red;
   }
  14% {
      color: cyan;
  }
  28% {
      color: pink;
  }
  42% {
      color: hotpink;
  }
  56% {
      color: green;
  }
  70% {
      color: orange;
  }
  84% {
      color: purple;
  } 
  100% {
      color: blue;
  }*/
  0% {
    color: #fff;
    transform: scale(1.0);
 }
 100% {
  color: #000;
  transform: scale(1.03);
}
}




.fullWidthDarkGray {
  width: 100%;
  background-color:#1d1d1d;
}

.space-apart {
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginTop1 {
  margin-top: 1rem;
}

.marginTop2 {
  margin-top: 2rem;
}

.marginTop3 {
  margin-top: 3rem;
}

.marginTop4 {
  margin-top: 4rem;
}

.marginBottom1 {
  margin-bottom: 1rem;
}

.marginBottom2 {
  margin-bottom: 2rem;
}

.marginBottom3 {
  margin-bottom: 3rem;
}

.marginBottom4 {
  margin-bottom: 4rem;
}

.largeHeader {
  font-size: 2.5rem;
  color: #ffffff;
}

.dropdown-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
}

.selectDropdown {
  margin-top: 6px;
  appearance: none;
  background-color: #FAFBFC;
  color:black;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.selectDropdown * {
  background-color: #FAFBFC;
  color:black;
}

.largeHeader {
  font-size: 2.5rem;
  color: #ffffff;
  margin: 0;
}

.smLabel {
  color:white;
  font-size:16px;
}

.whiteText {
  color: #FFF;
}

.pointer {
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.Modal {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  margin: auto;
  color: #000;
  position: relative;
}

.WiderModal {
  width: fit-content;
  max-width: 1200px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  margin: auto;
  color: #000;
  position: relative;
}

.WidestModal {
  width: fit-content;
  max-width: 90%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  margin: auto;
  color: #000;
  position: relative;
}

.WideModalPadding {
  width: fit-content;
  max-width: 90%;
  padding: 2rem 2.25rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  margin: auto;
  color: #000;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .WideModalPadding {
    height: auto;
    max-height: 87vh;
    overflow-y: auto;
  }
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer:hover {
  cursor: pointer;
}

.centered {
  text-align: center;
}