.homePageContainer {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
}

.fsBackground {
  z-index: 0;
  background-size: 5.2rem 5.2rem;
  background-color: rgb(20, 20, 20);
  background-image: linear-gradient(
      to right,
      rgb(230, 230, 230) 1px,
      transparent 0
    ),
    linear-gradient(to bottom, rgb(230, 230, 230) 1px, transparent 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fsBackground::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 0.8) 1%,
    rgba(20, 20, 20, 0.96) 40%,
    rgba(23, 23, 23, 1) 100%
  );
  z-index: -1;
}

.mainContentDivIntro {
  /* Spreading out items vertically */
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  flex: 1;
  margin: 0.3 0;
}

.homePageAvatar {
  width: 100%;
  height: auto;
  border-radius: 3px;
  display: inline;
}

.main-content {
  display: grid;
  grid-template-columns: 78% 22%;
  grid-template-rows: subgrid;
  gap: 1rem;
  margin: 0 20%;
  margin-top: 4.5%;
  max-height: 5px !important;
  align-items: start;
}

.mainContentHome {
  grid-column: 1 / 2;
  grid-row: 1;
  padding: 0.9rem 1rem;
  border: 1px solid #2f2f2f;
  color: rgb(219, 219, 219);
  font-size: 1.15vw;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  font-weight: 500;
}

.triGridColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  grid-template-rows: auto auto; /* Two rows */
  gap: 0 1rem;
  color: rgb(219, 219, 219);
}

.bottomGrid {
  grid-column: 2 / -1;
  grid-row: 2;
  gap: 1rem;
  display: grid;
  grid-template-columns: 7.75fr 2.25fr;
}

.bottomGridLong {
   /* First column in bottom grid */
  grid-column: 1;
  padding: 0 0.9rem;
  border: 1px solid #2f2f2f;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.bottomGridShort {
  /* Second column in bottom grid */
  grid-column: 2;
  padding: 0.2rem 0.9rem;
  font-size: 0.8vw;
  border: 1px solid #2f2f2f;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.madeWithReact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  min-height: 2.75rem;
  font-size: 0.8vw;
  text-align: center;
}

.triBlockHome {
  flex: 0.33333333333;
  min-height: 3.1vw;
  padding: 0.6rem 0.9rem;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  height: 65%;
  border: 1px solid #2f2f2f;
}

.tallHomeBlock {
  grid-column: 1;
  grid-row: 1 / span 2;
  padding: 0.6rem 0.9rem;
  min-height: 100%;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid #2f2f2f;
}

.homeBottomInterior {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  min-height: 2.75rem;
  font-size: 1.2vw;
  text-align: center;
}

.portfolioBackground {
  position: relative;
}

svg {
  transition: 0.25s ease-in-out;
}

#portfolioBlock:hover .portfolioBlockHome svg {
  transform: scale(1.15) translateX(5px);
}

/* Background image for this block */
.portfolioBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../img/home/code_bg.jpg");
  background-position: center;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -5;
}

/* Gray overlay over the background image to darken it */
.portfolioBackground::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(14, 14, 14, 0.486);
  z-index: 2;
}

.portfolioBlockHome {
  position: relative;
  z-index: 5;
  transition: 1s ease-in-out;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.25vw;
}

.portfolioBackground > * {
  position: relative;
  z-index: 5;
}

.portfolioBlock {
  grid-column: 2; /* Second column */
  grid-row: 1; /* First row */
  flex: 0.33333333333;
  padding: 0.6rem 0.9rem;
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
    rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  height: 65%;
  border: 1px solid #2f2f2f;
}

.colorBlockHome {
  grid-column: 3; /* Third column */
  grid-row: 1; /* First row */
  display: flex;
  justify-content: center;
  vertical-align: top;
  align-items: center;
  gap: 0.4rem;
  height: 100%;
}


.circle {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}

.circle:hover, .circle:active {
  transform: scale(1.1);
}

/* CHANGE COLOR SECTION */
.redBG {
  background-color: rgb(189, 17, 17);
}
.yellowBG {
  background-color: rgb(212, 177, 21);
}
.greenBG {
  background-color: rgb(36, 163, 19);
}
.blueBG {
  background-color: rgb(9, 176, 226);
}
.pinkBG {
  background-color: rgb(212, 13, 238);
}
.blueBoxShadow {
  box-shadow: rgb(9, 176, 226) 3px 3px 4px, rgba(0, 0, 0, 0.3) 0px 7px 15px -3px,
    rgba(4, 217, 255, 0.13) 1px -1.5px 2px inset;
}
.yellowBoxShadow {
  box-shadow: rgb(212, 177, 21) 3px 3px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 15px -3px,
    rgba(196, 176, 5, 0.2) 1px -1.5px 2px inset;
}
.redBoxShadow {
  box-shadow: rgb(189, 17, 17) 3px 3px 4px, rgba(0, 0, 0, 0.3) 0px 7px 15px -3px,
    rgba(197, 4, 4, 0.2) 1px -1.5px 2px inset;
}
.greenBoxShadow {
  box-shadow: rgb(36, 163, 19) 3px 3px 4px, rgba(0, 0, 0, 0.3) 0px 7px 15px -3px,
    rgba(20, 185, 4, 0.2) 1px -1.5px 2px inset;
}
.pinkBoxShadow {
  box-shadow: rgb(212, 13, 238) 3px 3px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 15px -3px,
    rgba(212, 13, 238, 0.219) 1px -1.5px 2px inset;
}
.blueHoverBorderEffectHome:hover, .blueHoverBorderEffectHome:active {
  border: 1px solid rgb(9, 176, 226);
}
.yellowHoverBorderEffectHome:hover, .yellowHoverBorderEffectHome:active {
  border: 1px solid rgb(212, 177, 21);
}
.redHoverBorderEffectHome:hover, .redHoverBorderEffectHome:active {
  border: 1px solid rgb(189, 17, 17);
}
.greenHoverBorderEffectHome:hover, .greenHoverBorderEffectHome:active {
  border: 1px solid rgb(36, 163, 19);
}
.pinkHoverBorderEffectHome:hover, .pinkHoverBorderEffectHome:active {
  border: 1px solid rgb(212, 13, 238);
}
/* END OF CHANGE COLOR SECTION */

.sideContentHome {
  max-height: 100%;
  font-size: 0.78vw;
  border: 1px solid #2f2f2f;
  grid-column: 2 / 3;
  grid-row: 1 / span 2;
  padding: 0.825rem 1rem 0.2rem 1rem;
  color: rgb(219, 219, 219);
  background: rgba(53, 53, 53, 0.192);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 7px,
  rgba(0, 0, 0, 0.3) 0px 9px 15px -5px, rgba(0, 0, 0, 0.2) 0px -3px 0px;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  height: fit-content;
  min-height: 100%;
}

.main-content .testblock-content {
  position: relative;
}

.anchorNoDecoration {
  text-decoration: none;
}

.homeMyStack {
  padding: 0.6rem 0 0 0;
}

.homeListItemsStack {
  color: rgb(219, 219, 219);
}

.homeMyStackInner {
  padding: 0.1rem 0 0 0.5rem;
}

h5.aboutMeH5 {
  font-weight: 700;
  font-size: 0.9vw;
  color: rgb(219, 219, 219);
}

.sidePanelLowerPart {
  font-size: 0.9rem
}

.aboutMeSidePanel {
  color: rgb(219, 219, 219);
}

.homeIcons {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
}

.homeSlightScaleOnHover:hover, .homeSlightScaleOnHover:active {
  transform: scale(1.03);
}

.homeIndividualIcon {
  padding: 0.6rem;
  margin: 0 1rem 0 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.homeIndividualIcon:hover {
  animation: pulseIconHome 1.25s infinite;
  transform: translateY(-1rem);
}

@keyframes pulseIconHome {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.065) translateY(-0.2rem);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.hoverEffectHomePage:hover {
  cursor: pointer;
}

.balloonGame {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: left;
  gap: 0.75rem;
  padding: 0.35rem;

}

.checkbox-wrapper-22 .switch {
  display: inline-block;
  height: 31px;
  position: relative;
  width: 54px;
}

.checkbox-wrapper-22 .switch input {
  display: none;
}

.checkbox-wrapper-22 .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.checkbox-wrapper-22 .slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

.checkbox-wrapper-22 input:checked + .slider {
  background-color: #434643;
}

.checkbox-wrapper-22 input:checked + .slider:before {
  transform: translateX(26px);
}

.checkbox-wrapper-22 .slider.round {
  border-radius: 34px;
}

.checkbox-wrapper-22 .slider.round:before {
  border-radius: 50%;
}

.balloonCounterGroup {
  display: inline-block;
}

.balloonCounter {
  vertical-align: top;
}

.balloonCounterSpan {
  color: rgb(219, 219, 219);
  vertical-align: top;
}


.balloonCounter,
.balloonGame {
  font-size: 1.15vw;
}

.balloonGameFlexOnOffAndCounter {
  display: flex;
  justify-content: left;
  gap: 2rem;
}

.inlineBlockDiv {
  display: inline-block;
}

.balloonGameElement {
  text-align: left;
}

.clockSpan {
  color: rgb(219, 219, 219);
}

.clockFlexGroup {
  flex: 0.66666666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

#mobileHomeAboutMe {
  display: none;
}

.balloonGameOnOrOff {
  color: rgb(219, 219, 219);
  margin-right: 0.5rem;
  vertical-align: top;
  display: inline-block;
  align-items: center;
}


/* Mobile portrait */
@media screen and (max-width: 812px) and (orientation: portrait) {

  .homePageContainer {
    position: absolute;
  }

  .main-content, .mainContentDivIntro {
    display: block;
    min-width: 85%;
    text-align: center;
    padding: 2px 0;
    margin: 1rem 1rem;
  }
  
  .homeIndividualIcon {
    padding: 7px 6px;
  }

  .homeIcons {
    justify-content: center;
    margin-left: 4px;
  }

  .avatarSection {
    position: relative;
  }
  
  .avatarSection > img {
    margin-top: -15%;
    width: 63%;
  }

  .triGridColumn {
    display: flex;
    flex-flow: column;
    flex-direction: column;
    grid-template-columns: none;
    grid-template-rows: none;
    order: 1;
  }

  .tallHomeBlock, .triBlockHome, #portfolioBlock {
    display: block;
    margin: 12px 0 0 0;
    grid-column: none;
    grid-row: none;
  }

  .mainContentHome {
    padding: 0.2rem 3px 0 3px;
  }

  #portfolioBlock {
    display: flex;
    align-items: center;
    order: 1;
    min-height: 5.5rem;
    font-size: 30px;
  }
  
  .portfolioBlockHome {
    display: flex;
    align-items: center;
    font-size: 24px;
    justify-content: space-between;
  }

  #colorBlock {
    order: 2;
  }
  .tallHomeBlock {
    order: 3;
  }
  .bottomGrid {
    order: 5;
  }

  .sideContentHome {
    grid-column: none;
    grid-row: none;
    display: block;
    order: 4;
  }

  .bottomGrid {
    display: none;
  }

  #desktopOnlyH4Home, #sideHome {
    display: none;
  }

  #mobileHomeAboutMe {
    margin-top: 1rem;
    display: block;
    font-size: 1rem;
    text-align: left;
    justify-content: left;
  }

  h5.aboutMeH5 {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
  .sidePanelLowerPart {
    font-size: 0.95rem;
  }

  .balloonGame {
    font-size: 1.5rem;
  }

  #balloonGameBlock {
    padding-bottom: 1.25rem;
  }

  .balloonCounterSpan {
    font-size: 1.3rem;
  }

  .colorBlockHome {
    gap: 1.25rem;
  }
}
/* End mobile portrait */



/* Mobile landscape */

@media screen and (orientation: landscape) and (max-width: 770px) {
  .homePageAvatar {
    display: none;
  }
}

@media screen and (orientation: landscape) and (max-width: 1200px) {
  .homePageContainer {
    position: absolute;
  }
  
  .main-content {
    display: block;
    min-width: 90%;
    text-align: center;
    padding: 2px 0;
    margin: 1rem 1rem;
  }

  .mainContentDivIntro {
    padding: 1rem 0;
    margin: 0 1.5rem;
    justify-content: left;
    text-align: left;
  }
  
  .homeIndividualIcon {
    padding: 7px 6px;
  }

  .homeIcons {
    justify-content: left;
    margin-left: 2px;
  }

  .triGridColumn {
    display: flex;
    flex-flow: column;
    flex-direction: column;
    grid-template-columns: none;
    grid-template-rows: none;
    order: 1;
  }

  .tallHomeBlock, .triBlockHome, #portfolioBlock {
    display: block;
    margin: 12px 0 0 0;
    grid-column: none;
    grid-row: none;
  }

  .mainContentHome {
    grid-column: none;
    grid-row: none;
    padding: 0.2rem 3px 0 3px;
  }

  .avatarSection > img {
    width: 90%;
    margin-right: 2rem;
  }
  
  #portfolioBlock {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    order: 1;
    min-height: 5.5rem;
    font-size: 30px;
  }
  
  .portfolioBlockHome {
    display: flex !important;
    align-items: center !important;
    font-size: 24px;
    justify-content: space-between;
  }

  #balloonGameBlock {
    padding-bottom: 1.25rem;
  }

  #colorBlock {
    order: 2;
  }
  .tallHomeBlock {
    order: 3;
  }
  .bottomGrid {
    order: 5;
  }

  .sideContentHome {
    grid-column: none;
    grid-row: none;
    display: block;
    order: 4;
  }

  .bottomGrid {
    display: none;
  }

  #sideHome {
    display: none;
  }

  #mobileHomeAboutMe {
    margin-top: 1rem;
    display: block;
    font-size: 1rem;
    text-align: left;
    justify-content: left;
  }

  h5.aboutMeH5 {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
  .sidePanelLowerPart {
    font-size: 0.95rem;
  }

  .balloonGame {
    font-size: 1.5rem;
  }

  .balloonCounterSpan {
    font-size: 1.3rem;
  }

  
  .colorBlockHome {
    gap: 3rem;
  }
}